import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppointmentDetailCardComponent } from 'src/app/components/appointment-detail-card/appointment-detail-card.component';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { AppointmentService } from 'src/app/common/services/appointment/appointment.service';
import { DEFAULT_START_ROUTE } from 'src/app/app.routes';
import { AppInitService } from 'src/app/app-init.service';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { RouteService } from 'src/app/common/services/route/route.service';
import { Title } from '@angular/platform-browser';
import { getEmbedded } from 'src/app/common/constants/general';
import { HeroComponent } from 'src/app/components/hero/hero.component';
import { AppointmentSelection } from 'src/app/common/model/appointment';

@Component({
  selector: 'app-appointment-confirmed',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FooterComponent,
    AppointmentDetailCardComponent,
    HeroComponent,
  ],
  templateUrl: './appointment-confirmed.component.html',
  styleUrls: ['./appointment-confirmed.component.scss'],
})
export class AppointmentConfirmedComponent implements OnInit, OnDestroy {
  localeSubscription: Subscription = new Subscription();
  locationName = '';
  loadingObj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean>;
  set loading(isLoading: boolean) {
    this.loadingObj.next(isLoading);
  }
  get loading(): boolean {
    return this.loadingObj.getValue();
  }

  selectedVaccineName?: string;
  selectedAppointment?: AppointmentSelection;
  suppressSearchHeader = false;

  constructor(
    private environmentService: EnvironmentService,
    private routeService: RouteService,
    private appointmentService: AppointmentService,
    private appInitService: AppInitService,
    private location: Location,
    private translateService: TranslateService,
    private titleService: Title
  ) {
    this.suppressSearchHeader = this.environmentService.suppressSearchHeader;

    this.locationName = this.appointmentService.selectedAppointment?.location
      ?.locationName as string;
    this.loading$ = this.loadingObj.asObservable();

    if (this.appointmentService.selectedAppointment) {
      this.selectedAppointment = this.appointmentService.selectedAppointment;
    }

    // listen for locale changed so we can update the time UI with the proper locale format
    this.localeSubscription = this.environmentService.localeChanged.subscribe(() => {
      // retranslate vaccine instant translations if the locale changes
      this.setSelectedVaccineTranslations();
    });
  }

  ngOnInit() {
    if (!getEmbedded()) {
      this.titleService.setTitle(this.translateService.instant('PAGES.CONFIRMATION.TITLE'));
    }

    this.setSelectedVaccineTranslations();
  }

  ngOnDestroy() {
    if (this.localeSubscription) {
      this.localeSubscription.unsubscribe();
    }
  }

  async done() {
    // set loading state to prevent futher clicks
    this.loading = true;

    // create a new session for the user after leaving confirmation
    this.environmentService.recreateUserId();

    // if this is an embedded experience, we don't want to redirect the user to the default route
    // they have to stay on the same campaign
    if (!getEmbedded()) {
      // clear current saved reservation and campaign state since we're defaulting to 0
      this.appointmentService.reservation = undefined;
      this.environmentService.configuration = null;
      this.environmentService.selectedService = undefined;
      this.environmentService.selectedVaccine = undefined;

      this.environmentService.campaignId = DEFAULT_START_ROUTE;

      // update the path in the url so when we configure in the next step we're routed properly to default
      this.location.replaceState(`${DEFAULT_START_ROUTE}`);
    }

    // init the app again because we have to call the config service again since we are switching campaigns
    try {
      await this.appInitService
        .init(false)
        .catch((error) => {
          this.loading = false;
        })
        .then(() => {
          this.appInitService.configureUrl();
          this.loading = false;
        });
    } catch (error) {
      this.loading = false;
    }

    // route the user to the service picker screen
    const router = this.routeService.getRouter();

    let route = this.routeService.getServicesRoute();

    if (getEmbedded()) {
      route = this.routeService.getAppointmentsRoute();
    }

    router.navigate([route]);
  }

  private setSelectedVaccineTranslations() {
    const { selectedVaccineName } = this.environmentService;
    if (selectedVaccineName) {
      this.selectedVaccineName = this.translateService.instant(selectedVaccineName);
    }
  }
}
