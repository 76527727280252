import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { Observable, Subscription } from 'rxjs';
import { EnvironmentService } from './common/services/environment/environment.service';
import { LanguagePickerButtonComponent } from './components/language-picker/language-picker-button/language-picker-button.component';
import { getEmbedded, getEmbeddedAssetsUrl } from './common/constants/general';
import { LogoComponent } from './components/logo/logo.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    LanguagePickerComponent,
    LanguagePickerButtonComponent,
    LogoComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  styleUrls: ['../styles.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy, OnInit {
  languagePickerButtonEnabled = false; // whether or not the current screen should have the language picker
  shouldShowLanguagePicker$: Observable<boolean>;
  shouldShowLanguagePickerButton = false;
  suppressSearchHeader = false;
  subscriptions = new Subscription();
  easyVaxLogoUrl = 'assets/easyvax-logo.png';

  constructor(
    private environmentService: EnvironmentService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.shouldShowLanguagePicker$ = this.environmentService.shouldShowLanguagePicker$;
    this.shouldShowLanguagePickerButton = this.environmentService.shouldShowLanguagePickerButton;
    this.suppressSearchHeader = this.environmentService.suppressSearchHeader;

    const routeSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkRouteForLanguagePickerEnabled();
      }
    });

    this.subscriptions.add(routeSub);
  }

  ngOnInit(): void {
    this.checkRouteForLanguagePickerEnabled();

    if (getEmbedded()) {
      this.easyVaxLogoUrl = `${getEmbeddedAssetsUrl()}/assets/EasyVaxLogo.png`;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // Check the activated route to determine visibility of the language picker button
  private checkRouteForLanguagePickerEnabled() {
    this.languagePickerButtonEnabled = false;

    if (this.route.firstChild) {
      const routeData = this.route.firstChild.snapshot.data;
      this.languagePickerButtonEnabled = routeData['languagePickerButtonEnabled'] ?? false; // Use route data to control visibility
    }
  }
}
