import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Location } from 'src/app/common/model/location';
import {
  AppointmentDateTime,
  AppointmentSelection,
  AppointmentTimesResponse,
} from 'src/app/common/model/appointment';
import { AppointmentService } from 'src/app/common/services/appointment/appointment.service';
import { DateUtil } from 'src/app/common/utils/date';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertPromptExternalUrlService } from 'src/app/common/services/alert-prompt/alert-prompt-external-url.service';
import { EventService } from 'src/app/common/services/event/event.service';
import { AlertPromptType } from 'src/app/common/types/alert-prompt-type';
import { AlertPromptBase } from 'src/app/common/model/alert-prompt/alert-prompt-base';
import { AlertPromptDirections } from 'src/app/common/model/alert-prompt/alert-prompt-directions';
import { AlertPromptPharmacyWebsite } from 'src/app/common/model/alert-prompt/alert-prompt-pharmacy-website';
import {
  AnalyticsEventTriggerType,
  AnalyticsEventType,
} from 'src/app/common/types/track-event-type';
import { AnalyticsEvent } from 'src/app/common/model/event';
import { DateTime } from 'luxon';
import { LocationAddressComponent } from 'src/app/components/location-address/location-address.component';
import { TranslateModule } from '@ngx-translate/core';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';

@Component({
  selector: 'app-location-integration-level-base',
  standalone: true,
  imports: [CommonModule, TranslateModule, LocationAddressComponent],
  templateUrl: './location-integration-level-base.component.html',
  styleUrls: ['./location-integration-level-base.component.scss'],
})
export class LocationIntegrationLevelBaseComponent implements OnInit, OnDestroy {
  @ViewChild('card', { static: true }) cardRef!: ElementRef;

  @Input({ required: true }) location!: Location;
  @Input() shouldShowLocationFooter = false;
  @Input() shouldShowPhoneNumber = false;

  @Output() viewLocationDetail: EventEmitter<Location> = new EventEmitter<Location>();
  @Output() openExternalUrl: EventEmitter<string> = new EventEmitter();
  @Output() selectedAppointment: EventEmitter<AppointmentSelection | undefined> = new EventEmitter<
    AppointmentSelection | undefined
  >();
  @Output() sendAnalyticsEvent: EventEmitter<void> = new EventEmitter<void>();

  appointments: AppointmentDateTime[] = []; // location.appointments and times mapped to a new class for easy use in the template
  get cardRefNativeElement() {
    return this.cardRef.nativeElement;
  }
  filteredAppointments: AppointmentDateTime[] = []; // filtered location.appointments and times mapped to a new class for easy use in the template
  loadingObj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean>;
  set loading(isLoading: boolean) {
    this.loadingObj.next(isLoading);
  }
  truncatedNumAppointments = 0; // the number of "other" appointments
  numAppointmentsPerRow = 0; // number of appointment time buttons that fit in one row
  startDate: string; // appointment search startDate
  startTime: string; // appointment search startTime
  suppressSearchHeader = false;
  private timeoutAppointmentTimes: any;
  private timeoutAppointmentFilter: any;

  constructor(
    private appointmentService: AppointmentService,
    private alertPromptExternalUrlService: AlertPromptExternalUrlService,
    private eventService: EventService,
    private environmentService: EnvironmentService
  ) {
    this.loading$ = this.loadingObj.asObservable();
    this.startDate = this.appointmentService.startDate;
    this.startTime = this.appointmentService.startTime;
    this.suppressSearchHeader = this.environmentService.suppressSearchHeader;
  }

  // Listen for resize changes so we can calculate the availble timeslots
  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.setState();
  }

  ngOnInit() {
    this.setAppointmentTimes();
  }

  ngOnDestroy() {
    // Clear the timeout if the component is destroyed before the callback is executed
    if (this.timeoutAppointmentTimes) {
      clearTimeout(this.timeoutAppointmentTimes);
    }

    if (this.timeoutAppointmentFilter) {
      clearTimeout(this.timeoutAppointmentFilter);
    }
  }

  filterAppointmentDateTimes(appointmentDateTimes: AppointmentDateTime[]) {
    const { startTime, startDate } = this;
    // Convert the filtered date and time to a Luxon DateTime object
    const isoDate = `${startDate}T${startTime}`;
    const filterDateTime = DateUtil.formatDateFromIso(isoDate);
    const now = DateUtil.currentDateUTC;

    this.filteredAppointments = appointmentDateTimes.filter((appointment) => {
      // if the appointment date is in the future of the selected filter date display it
      if (appointment.date > this.startDate) {
        return true;
      }

      const appointmentTimeObj = DateUtil.formatDateFromIso(appointment.time);

      return (
        appointmentTimeObj >= filterDateTime &&
        appointmentTimeObj >= now &&
        appointment.date >= this.startDate
      );
    });

    // Clear any existing timeout to prevent memory leaks
    if (this.timeoutAppointmentFilter) {
      clearTimeout(this.timeoutAppointmentFilter);
    }

    // need to delay setting state to give the view enough time to populate
    this.timeoutAppointmentFilter = setTimeout(() => {
      this.setState();
      this.timeoutAppointmentFilter = null;
    }, 1);
  }

  /**
   * Get the list of available appointment times for a vaccine given a location and date
   */
  getAppointmentTimes() {
    this.loading = true;

    const { locationId } = this.location;
    this.appointmentService.getAppointmentTimes(locationId).subscribe({
      next: (appointmentTimes: AppointmentTimesResponse) => {
        if (appointmentTimes) {
          // Set the appointments on the location
          this.location.appointments = appointmentTimes.appointments;

          // Map the new appointment times
          this.setAppointmentTimes();

          this.loading = false;
        }
      },
      error: (error: any) => {
        // on error we clear location appointments
        this.location.appointments = [];
        // calling this will clear the appointments used in the template
        this.setAppointmentTimes();

        this.loading = false;
      },
    });

    // Clear any existing timeout to prevent memory leaks
    if (this.timeoutAppointmentTimes) {
      clearTimeout(this.timeoutAppointmentTimes);
    }

    // need to delay setting state to give the view enough time to populate
    // Set a new timeout to update the state
    this.timeoutAppointmentTimes = setTimeout(() => {
      this.setState();
      this.timeoutAppointmentTimes = null; // // Reset the timeoutId after the callback is executed
    }, 1);
  }

  /**
   * Takes all of the location appointments and maps them into one array so we can slice it for the card display
   */
  setAppointmentTimes() {
    const appointmentDateTimes: AppointmentDateTime[] = [];

    if (this.location?.appointments) {
      this.location.appointments.forEach((appointment) => {
        const { date } = appointment;

        appointment.times.forEach((time) => {
          appointmentDateTimes.push(
            new AppointmentDateTime(
              time.appointmentId,
              date,
              time.time,
              DateUtil.formatDate(date),
              DateUtil.formatTime(time.time),
              DateUtil.dateIsToday(date),
              time.bookingUrl ?? '',
              time.duration
            )
          );
        });
      });
    }

    this.appointments = appointmentDateTimes;
    this.filterAppointmentDateTimes(appointmentDateTimes);
  }

  selectedAppointmentTime(appointment: AppointmentDateTime) {
    const { location } = this;

    const appointmentSelection: AppointmentSelection = {
      location: location,
      locationId: location.locationId,
      appointmentId: appointment.appointmentId,
      date: appointment.date,
      time: appointment.time,
      supportsApiScheduling: location.supportsApiScheduling,
      appointmentBookingUrl: appointment.bookingUrl,
      locationBookingUrl: location.bookingUrl ?? '',
      duration: appointment.duration,
    };

    this.selectedAppointment.emit(appointmentSelection);
  }

  public setAlertPrompt(type: AlertPromptType) {
    let prompt: AlertPromptBase<any> | undefined;
    switch (type) {
      case 'directions':
        prompt = new AlertPromptDirections();
        break;
      case 'pharmacy-website':
        prompt = new AlertPromptPharmacyWebsite();
        break;
      default:
        break;
    }
    if (prompt) {
      this.alertPromptExternalUrlService.setAlertPrompt(prompt);
    }
  }

  setAnalyticsEvent(eventType: AnalyticsEventType, trigger: AnalyticsEventTriggerType) {
    const eventTimestamp = DateTime.local().toUTC().toString();
    const analyticsEvent: AnalyticsEvent = {
      eventType: eventType,
      eventTimestamp: eventTimestamp,
      trigger: trigger,
      location: this.location,
    };

    this.eventService.setAnalyticsEvent(analyticsEvent);
  }

  public setState() {
    // overridden in extended location integration components
  }
}
