import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DynamicFormComponent } from 'src/app/components/dynamic-form/dynamic-form.component';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { QuestionBase } from 'src/app/common/model/question/question-base';
import {
  AppointmentHoldResponse,
  AppointmentReservation,
  AppointmentReservationResponse,
  AppointmentSelection,
} from 'src/app/common/model/appointment';
import { AppointmentService } from 'src/app/common/services/appointment/appointment.service';
import { QuestionService } from 'src/app/common/services/question/question.service';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { FormGroup } from '@angular/forms';
import { QuestionControlService } from 'src/app/common/services/question/question-control.service';
import { LoadingDynamicFormComponent } from 'src/app/components/loading-dynamic-form/loading-dynamic-form.component';
import { RouteService } from 'src/app/common/services/route/route.service';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { ModalType } from 'src/app/common/types/modal-type';
import { InertDirective } from 'src/app/directives/inert/inert.directive';
import { TermsAndPrivacyModalComponent } from 'src/app/components/terms-and-privacy-modal/terms-and-privacy-modal.component';
import { transition, trigger, useAnimation } from '@angular/animations';
import { FADE_IN_ANIMATION, FADE_OUT_ANIMATION } from 'src/app/common/constants/animations';
import { VaxError } from 'src/app/common/model/error';
import { ServiceErrorCodes, getEmbedded } from 'src/app/common/constants/general';
import { EmbeddedRouter } from 'src/app/easyvax-embed/embedded-router';
import { HeroComponent } from 'src/app/components/hero/hero.component';

@Component({
  selector: 'app-appointment-reservation',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DynamicFormComponent,
    LoadingDynamicFormComponent,
    InertDirective,
    TermsAndPrivacyModalComponent,
    HeroComponent,
  ],
  providers: [QuestionService, QuestionControlService],
  templateUrl: './appointment-reservation.component.html',
  styleUrls: ['./appointment-reservation.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [useAnimation(FADE_IN_ANIMATION)]),
      transition(':leave', [useAnimation(FADE_OUT_ANIMATION)]),
    ]),
  ],
})
export class AppointmentReservationComponent implements OnInit, OnDestroy, AfterViewInit {
  questions: QuestionBase<any>[] = [];
  formData?: any;
  localeSubscription: Subscription = new Subscription();
  locationName = '';
  loadingObj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean>;

  get error() {
    return this.appointmentService.error;
  }

  set loading(isLoading: boolean) {
    this.loadingObj.next(isLoading);
  }
  get loading(): boolean {
    return this.loadingObj.getValue();
  }
  prettyDate?: string;
  prettyTime?: string;
  selectedVaccineName?: string;
  selectedAppointment?: AppointmentSelection;
  safeTermsAndPrivacyHtml: SafeHtml = '';
  showTermsAndPrivacyModal = false;
  suppressSearchHeader = false;
  modalType: ModalType = 'terms';

  private termsAnchorListener?: () => void;
  private privacyAnchorListener?: () => void;

  constructor(
    private routeService: RouteService,
    private location: Location,
    private appointmentService: AppointmentService,
    private questionService: QuestionService,
    private environmentService: EnvironmentService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private titleService: Title,
    private el: ElementRef
  ) {
    this.loading$ = this.loadingObj.asObservable();

    this.suppressSearchHeader = this.environmentService.suppressSearchHeader;

    if (this.appointmentService.selectedAppointment) {
      this.locationName = this.appointmentService.selectedAppointment.location.locationName;
      this.selectedAppointment = this.appointmentService.selectedAppointment;
    }

    // listen for locale changed so we can update the time UI with the proper locale format
    this.localeSubscription = this.environmentService.localeChanged.subscribe(() => {
      // retranslate vaccine instant translations if the locale changes
      this.setSelectedVaccineTranslations();
      this.setAppointmentPrettyDateTime();
      this.setTermsAndConditions();
    });

    // Get the dynamic questions
    this.getQuestions();

    // Determine if we have existing formData to auto-populate dynamic form values
    this.setFormData();
  }

  ngOnInit() {
    if (!getEmbedded()) {
      this.titleService.setTitle(this.translateService.instant('PAGES.REQUIRED_INFORMATION.TITLE'));
    }

    this.setSelectedVaccineTranslations();
    this.setAppointmentPrettyDateTime();
    this.setTermsAndConditions();
  }

  ngOnDestroy(): void {
    this.localeSubscription.unsubscribe();

    if (this.termsAnchorListener) {
      this.termsAnchorListener(); // remove the listener by calling the function returned by renderer.listen()
    }

    if (this.privacyAnchorListener) {
      this.privacyAnchorListener(); // remove the listener by calling the function returned by renderer.listen()
    }
  }

  /**
   * Gets the JSON schema for the requested questionnaire
   * The questionService takes the response and maps it to our question classes
   */
  getQuestions() {
    this.loading = true;

    this.appointmentService.holdAppointment()?.subscribe({
      next: (response: AppointmentHoldResponse) => {
        // set the appointment hold data that will be used for the reservation
        this.appointmentService.appointmentHold = response;

        if (response?.questions) {
          this.questionService.getQuestions(response.questions).subscribe({
            next: (questions: QuestionBase<any>[]) => {
              this.questions = questions;
              this.loading = false;
            },
            error: (error: any) => {
              // TODO: do something with error
              this.questions = [];
              this.loading = false;
            },
          });
        } else {
          this.appointmentService.appointmentHold = undefined;
          this.questions = [];
          this.loading = false;
        }
      },
      error: (error: any) => {
        // TODO: do something with error
        this.appointmentService.appointmentHold = undefined;
        this.questions = [];
        this.loading = false;
      },
    });
  }

  leftButtonClick() {
    this.appointmentService.selectedAppointment = undefined;

    // keep the patient form data (additionalData) so we can attempt to auto-populate
    const additionalData = this.appointmentService.reservation?.additionalData;
    this.appointmentService.reservation = {
      duration: 0,
      additionalData: additionalData,
    };

    this.appointmentService.clearError();

    const router = this.routeService.getRouter();

    if (router instanceof EmbeddedRouter) {
      router.navigate([this.routeService.getAppointmentsRoute()]);
    } else {
      this.location.back(); // the user will be taken back to the previous page in the browser's history.
    }
  }

  setFormData() {
    const { reservation } = this.appointmentService;
    if (reservation) {
      this.formData = reservation.additionalData;
    }
  }

  private setSelectedVaccineTranslations() {
    const { selectedVaccineName } = this.environmentService;
    if (selectedVaccineName) {
      this.selectedVaccineName = this.translateService.instant(selectedVaccineName);
    }
  }

  private setAppointmentPrettyDateTime() {
    this.prettyDate = this.appointmentService.selectedAppointmentPrettyDate;
    this.prettyTime = this.appointmentService.selectedAppointmentPrettyTime;
  }

  private setTermsAndConditions() {
    // EasyVax Terms and Conditions handling
    const translatedTermsAndPrivacyHtml = this.translateService.instant(
      'APPOINTMENT.REVIEW.TERMS_DISCLAIMER'
    );
    this.safeTermsAndPrivacyHtml = this.sanitizer.bypassSecurityTrustHtml(
      translatedTermsAndPrivacyHtml
    );
  }

  submit(form: FormGroup) {
    const additionalData = form.getRawValue();
    const { campaignId, selectedVaccine } = this.environmentService;
    const { selectedAppointment, appointmentHold } = this.appointmentService;

    if (selectedAppointment && selectedVaccine) {
      const reservation: AppointmentReservation = {
        campaignId: campaignId,
        locationId: selectedAppointment.locationId,
        locationGroupId: selectedAppointment.location.locationGroupId,
        date: selectedAppointment.time,
        duration: selectedAppointment.duration,
        vaccineCode: selectedVaccine.vaccineCode,
        appointmentId: selectedAppointment.appointmentId,
        holdId: appointmentHold?.holdId,
        additionalData: additionalData,
      };

      this.appointmentService.reservation = reservation;

      this.setFormData();

      this.appointmentService.clearError();

      this.loading = true;

      // Call reserveValidate
      this.appointmentService?.reserveValidateAppointment()?.subscribe({
        next: (resp: AppointmentReservationResponse) => {
          this.loading = false;

          const router = this.routeService.getRouter();
          router.navigate([this.routeService.getReviewRoute()]);
        },
        error: (error: any) => {
          this.loading = false;
          this.handleServiceError(error.error);
        },
      });
    }
  }

  getElementContainer() {
    return this.el.nativeElement.getRootNode();
  }

  ngAfterViewInit(): void {
    const termsAnchor = this.getElementContainer().getElementById('terms');
    if (termsAnchor) {
      this.termsAnchorListener = this.renderer.listen(termsAnchor, 'click', (event: Event) => {
        event.preventDefault(); // prevent the window from reloading because of the # href
        this.showTerms();
      });
    }

    const privacyAnchor = this.getElementContainer().getElementById('privacy');
    if (privacyAnchor) {
      this.privacyAnchorListener = this.renderer.listen(privacyAnchor, 'click', (event: Event) => {
        event.preventDefault(); // prevent the window from reloading because of the # href
        this.showPrivacy();
      });
    }
  }

  /**
   * Terms and Privacy Modal Handling
   */
  dismissTermsAndPrivacyModal() {
    this.setShowTermsAndPrivacyModal(false);
  }

  setModalType(type: ModalType) {
    this.modalType = type;
  }

  // Shows or Hides the Privacy Policy or Terms and Conditions Modal
  // true: modal displays
  // false: modal hides
  setShowTermsAndPrivacyModal(shouldShow: boolean) {
    this.showTermsAndPrivacyModal = shouldShow;
  }

  /**
   * Opens the terms and conditions URL the server provided
   */
  showTerms() {
    if (this.showTermsAndPrivacyModal) {
      this.dismissTermsAndPrivacyModal();
    }

    setTimeout(() => {
      // set the proper url retrived from the appointment hold response
      const { appointmentHold } = this.appointmentService;
      if (appointmentHold?.termsAndConditionsPath) {
        this.setModalType('terms');
        this.setShowTermsAndPrivacyModal(true);
      }
    }, 1);
  }

  showPrivacy() {
    if (this.showTermsAndPrivacyModal) {
      this.dismissTermsAndPrivacyModal();
    }

    setTimeout(() => {
      // set the proper url retrived from the appointment hold response
      const { appointmentHold } = this.appointmentService;
      if (appointmentHold?.privacyPolicyPath) {
        this.setModalType('privacy');
        this.setShowTermsAndPrivacyModal(true);
      }
    }, 1);
  }

  handleServiceError(error: VaxError) {
    console.log('Vax Error being handled: ', error);
    let msg = '';

    switch (error.subCode) {
      case ServiceErrorCodes.SUBCODE_VALIDATION_ERROR:
        msg = this.translateService.instant('ERRORS.1000.MESSAGE');
        msg += ' (' + error.details[0].name + ': ' + error.details[0].reason + ')';

        this.appointmentService.setError(msg);
        break;
      case ServiceErrorCodes.SUBCODE_APPOINTMENT_NOT_AVAILABLE:
        msg = this.translateService.instant('ERRORS.1001.MESSAGE');
        this.appointmentService.setError(msg);
        break;
      case ServiceErrorCodes.SUBCODE_RETAILER_ERROR:
        msg = this.translateService.instant('ERRORS.1002.MESSAGE');
        msg += ' (' + error.details[0].name + ': ' + error.details[0].reason + ')';
        this.appointmentService.setError(msg);
        break;
      // 1003 = Invalid search address
      case ServiceErrorCodes.SUBCODE_RECAPTCHA_ERROR:
        msg = this.translateService.instant('ERRORS.1004.MESSAGE');
        this.appointmentService.setError(msg);
        break;
      case ServiceErrorCodes.SUBCODE_PRESCRIPTION_REQUIRED_ERROR:
        msg = this.translateService.instant('ERRORS.1005.MESSAGE');
        this.appointmentService.setError(msg);
        break;
      case ServiceErrorCodes.SUBCODE_SEASONAL_VACCINE_NOT_AVAILABLE:
        msg = this.translateService.instant('ERRORS.1006.MESSAGE');
        this.appointmentService.setError(msg);
        break;
      case ServiceErrorCodes.SUBCODE_PATIENT_TOO_YOUNG:
        msg = this.translateService.instant('ERRORS.1007.MESSAGE');
        this.appointmentService.setError(msg);
        break;
      case ServiceErrorCodes.SUBCODE_PATIENT_INELIGIBLE:
        msg = this.translateService.instant('ERRORS.1008.MESSAGE');
        this.appointmentService.setError(msg);
        break;
      default:
        this.appointmentService.setError(error.details[0].reason);
        break;
    }

    setTimeout(() => {
      // set the proper url retrived from the appointment hold response
      const { appointmentHold } = this.appointmentService;
      if (appointmentHold?.privacyPolicyPath) {
        this.setModalType('privacy');
        this.setShowTermsAndPrivacyModal(true);
      }
    }, 1);
  }
}
