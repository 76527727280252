import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VaxTranslationLoader } from './common/services/translation/vax-translation-loader';
import { Configuration } from './common/model/configuration';
import { ConfigurationService } from './common/services/configuration/configuration.service';
import { EnvironmentService } from './common/services/environment/environment.service';
import { TranslationService } from './common/services/translation/translation.service';
import { Observable, Subscription } from 'rxjs';
import { Settings } from 'luxon';
import { DEFAULT_LOCALE, getEmbedded } from './common/constants/general';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ThemeUtil } from './common/utils/theme';
import { RouteService } from './common/services/route/route.service';

export function translationLoaderFactory(translationService: TranslationService) {
  return new VaxTranslationLoader(translationService);
}

@Injectable()
export class AppInitService implements OnDestroy {
  configSubscription: Subscription = new Subscription();
  translationsSubscription: Subscription = new Subscription();
  private themeModeChangeListener: (event: MediaQueryListEvent) => void;

  constructor(
    private configurationService: ConfigurationService,
    private translateService: TranslateService,
    private environmentService: EnvironmentService,
    private translationService: TranslationService,
    private bsLocaleService: BsLocaleService,
    private routeService: RouteService
  ) {
    this.themeModeChangeListener = (event) => {
      this.onThemeModeChange(event.matches);
    };
  }

  ngOnDestroy(): void {
    this.unwatchThemeModeChanges();
  }

  /**
   * Used to initalize the application on load. This calls the configuration service, and
   * sets environment variables, theme, and loads translations based on the response
   *
   * @param configureEnvironment - (Boolean) whether or not we need to configure environment.
   * This allows us to init again when locale changes without having to configure the environment service again.
   *
   * @returns Promise
   */
  init(configureEnvironment = true) {
    return new Promise<void>((resolve, reject) => {
      // configure the environment
      if (configureEnvironment) {
        this.configureEnvironment();
      }

      // unsubscribe if we already have a subscription before creating a new one
      // could happen if we have to re-init (locale change, config error)
      if (this.configSubscription) {
        this.configSubscription.unsubscribe();
      }

      this.configSubscription = this.configurationService.getConfiguration().subscribe({
        next: (response: Configuration) => {
          // Set the configuration
          this.setEnvironmentConfiguration(response);

          // unsubscribe before creating a new one
          if (this.translationsSubscription) {
            this.translationsSubscription.unsubscribe();
          }

          this.translationsSubscription = this.configureTranslations().subscribe({
            next: () => {
              Settings.defaultLocale = this.environmentService.locale; // set default locale for DateTimes
              this.environmentService.localeChanged.emit(); // emit that it's changed so dates can update in UI
              resolve();
            },
            error: (error: any) => {
              Settings.defaultLocale = this.environmentService.locale; // set default locale for DateTimes
              this.environmentService.localeChanged.emit(); // emit that it's changed so dates can update in UI
              resolve();
            },
          });
        },
        error: (error: any) => {
          // Clear any configuration details so configure routes to oops
          this.environmentService.configuration = null;

          // unsubscribe before creating a new one
          if (this.translationsSubscription) {
            this.translationsSubscription.unsubscribe();
          }

          this.translationsSubscription = this.configureTranslations().subscribe({
            next: () => {
              Settings.defaultLocale = this.environmentService.locale; // set default locale for DateTimes
              this.environmentService.localeChanged.emit(); // emit that it's changed so dates can update in UI
              reject();
            },
            error: (error: any) => {
              Settings.defaultLocale = this.environmentService.locale; // set default locale for DateTimes
              this.environmentService.localeChanged.emit(); // emit that it's changed so dates can update in UI
              reject();
            },
          });
        },
      });
    });
  }

  private configureEnvironment() {
    this.environmentService.configure();
  }

  /**
   * Configures the translation loader and sets the default lang to use
   * @returns
   */
  private configureTranslations(): Observable<any> {
    this.translateService.currentLoader = translationLoaderFactory(this.translationService);

    const { locale } = this.environmentService;
    this.translateService.setDefaultLang(DEFAULT_LOCALE);
    this.translateService.reloadLang(locale);

    // configure the ngx-bootstrap datetime picker locale
    this.bsLocaleService.use(locale);

    return this.translateService.use(locale);
  }

  /**
   * Configures the URL path based on the variables that were set in the environment service.
   * Routes the user properly based on conditions
   */
  configureUrl() {
    const { configuration, selectedService, selectedVaccine } = this.environmentService;

    // root path with a campaignId takes users to services
    let path = this.routeService.getServicesRoute();

    // if we recieved an error from the config service route to the error page
    if (configuration === null) {
      path = this.routeService.getConfigErrorRoute();
    }

    // if we have selected service we should go directly to vaccines
    if (selectedService) {
      path = this.routeService.getVaccinesRoute();
    }

    // if we have selected vaccine we should go directly to appointments
    if (selectedVaccine) {
      path = this.routeService.getAppointmentsRoute();
    }

    // if user navigated to the url and included a zip, lat, lng we need to set it
    const queryParams = new URLSearchParams(window.location.search);
    const zip = queryParams.get('zip');
    // const lat = queryParams.get('lat');
    // const long = queryParams.get('lng');
    if (zip) {
      this.environmentService.selectedZip = zip;
    }

    if (path) {
      const router = this.routeService.getRouter();
      router.navigate([path]);
    }
  }

  private setEnvironmentConfiguration(response: Configuration) {
    this.environmentService.configuration = {
      baseUrl: response.baseUrl,
      campaignId: response.campaignId,
      defaultZipCode: response.defaultZipCode,
      defaultLocale: response.defaultLocale,
      locationGroupId: response.locationGroupId,
      selectedServiceCode: response.selectedServiceCode,
      selectedVaccineCode: response.selectedVaccineCode,
      schedulingWindowDays: response.schedulingWindowDays,
      suppressSearchHeader: response.suppressSearchHeader ?? false,
      suppressTermsAndPrivacy: response.suppressTermsAndPrivacy ?? false,
      resources: response.resources,
      services: response.services,
      theme: response.theme,
    };

    if (response.campaignId) {
      this.environmentService.campaignId = response.campaignId;
    }

    // When a defaultLocale is returned, and that default differs from the one the On Ramp requested
    // And we're not in override mode we need to set the locale to the campaigns
    // defaultLocale so the language picker respects the proper config
    if (
      response.defaultLocale &&
      response.defaultLocale !== this.environmentService.locale &&
      !this.environmentService.isLocaleOverride
    ) {
      // set the locale to the campaigns default
      this.environmentService.locale = response.defaultLocale;
      this.environmentService.showLanguagePicker();
    }

    this.setSelectedService(response);
    this.setSelectedVaccine(response);
    this.setSelectedZip(response);
    this.setResources(response);
    this.setTheme(response);
  }

  /**
   * Set the selectedService if we receive a selectedServiceCode from the config
   * @param response
   */
  private setSelectedService(response: Configuration) {
    if (response.selectedServiceCode) {
      for (const category of response.services) {
        for (const service of category.services) {
          if (
            service.serviceCode.toLocaleLowerCase() ==
            response.selectedServiceCode?.toLocaleLowerCase()
          ) {
            this.environmentService.selectedService = service;
          }
        }
      }

      // If we only have 1 vaccine for the selectedService and we dont have a selectedVaccineCode
      // pre-select it and skip vaccine selection
      if (
        this.environmentService?.selectedService?.vaccines.length === 1 &&
        !response.selectedVaccineCode
      ) {
        this.environmentService.selectedVaccine =
          this.environmentService?.selectedService?.vaccines[0];
      }
    }
  }

  /**
   * Set the selectedVaccine if we receive a selectedVaccineCode from the config
   * @param response
   */
  private setSelectedVaccine(response: Configuration) {
    if (response.selectedVaccineCode) {
      for (const category of response.services) {
        for (const service of category.services) {
          if (
            service.serviceCode.toLocaleLowerCase() ==
            response.selectedServiceCode?.toLocaleLowerCase()
          ) {
            this.environmentService.selectedVaccine = service.vaccines.find(
              (vaccine) =>
                vaccine.vaccineCode.toLocaleLowerCase() ==
                response.selectedVaccineCode?.toLocaleLowerCase()
            );
          }
        }
      }
    }
  }

  /**
   * Set the selected zip code if we receive a defaultZipCode from the config
   * @param response
   */
  private setSelectedZip(response: Configuration) {
    if (response.defaultZipCode) {
      this.environmentService.selectedZip = response.defaultZipCode;
    }
  }

  /**
   * Sets the resources the translation service will use from the config
   * @param response
   */
  private setResources(response: Configuration) {
    if (response.resources) {
      this.environmentService.resources = response.resources;
    }
  }

  /**
   * Sets the theme variables the app will use from the config
   * @param response
   */
  private setTheme(response: Configuration) {
    const {
      backgroundColor,
      backgroundColorDark,
      textColor,
      textColorDark,
      tintColor,
      tintColorDark,
      tintActiveColor,
      tintActiveColorDark,
    } = response.theme;

    let themeableComponent = document.documentElement;

    if (getEmbedded()) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      themeableComponent = document.querySelector('easyvax-embed')!;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      themeableComponent = document.querySelector('app-root')!;
    }

    if (response.theme) {
      /**
       * Light Mode
       */
      themeableComponent.style.setProperty('--theme-background-color', backgroundColor);
      themeableComponent.style.setProperty('--theme-text-color', textColor);
      themeableComponent.style.setProperty('--theme-tint-color', tintColor);
      themeableComponent.style.setProperty('--theme-tint-active-color', tintActiveColor);

      // DISABLE DARK MODE PER ERIC
      //   /**
      //    * Dark Mode
      //    */
      // if (backgroundColorDark) {
      //   themeableComponent.style.setProperty('--theme-background-color-dark', backgroundColorDark);
      // }

      // if (textColorDark) {
      //   themeableComponent.style.setProperty('--theme-text-color-dark', textColorDark);
      // }

      // if (tintColorDark) {
      //   themeableComponent.style.setProperty('--theme-tint-color-dark', tintColorDark);
      // }

      // if (tintActiveColorDark) {
      //   themeableComponent.style.setProperty('--theme-tint-active-color-dark', tintActiveColorDark);
      // }
    }

    // if (ThemeUtil.isDarkMode()) {
    //   themeableComponent.setAttribute('data-bs-theme', 'dark');
    // } else {
    themeableComponent.setAttribute('data-bs-theme', 'light');
    // }

    this.watchThemeModeChanges();
  }

  onThemeModeChange(matches: boolean) {
    let themeableComponent = document.documentElement;

    if (getEmbedded()) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      themeableComponent = document.querySelector('easyvax-embed')!;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      themeableComponent = document.querySelector('app-root')!;
    }

    // DISABLE DARK MODE PER ERIC
    // if (matches) {
    //   themeableComponent.setAttribute('data-bs-theme', 'dark');
    // } else {
    themeableComponent.setAttribute('data-bs-theme', 'light');
    // }
  }

  private watchThemeModeChanges() {
    // unwatch any existing listeners
    this.unwatchThemeModeChanges();

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', this.themeModeChangeListener);
    this.onThemeModeChange(ThemeUtil.isDarkMode());
  }

  private unwatchThemeModeChanges() {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .removeEventListener('change', this.themeModeChangeListener);
  }
}
