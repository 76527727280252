export const environment = {
  name: 'demo',
  production: false,
  version: '2.2.1',
  apiBaseUrl: 'https://mudlrwwyapyotpd.easyvax.org/api',
  apiLocalStorageErrorKey: 'vax-demo', // update localStorage to include this key with a value of * to force API errors for testing
  apiKey: '>$ZkT?Jb7bT>9iQW', // API key for backbone,
  gtagId: '', // Google Tag Mananger
  gFloodlightId: '', // Google Floodlight tracking
  tradeDeskPixelIsSupported: false, // Trade Desk Pixel tracking
  tradeDeskPixelUrl: 'https://js.adsrvr.org/up_loader.1.1.0.js',
  tradeDeskUniversalPixelUrl: 'https://insight.adsrvr.org/track/up',
  tradeDeskStaticPixelUrl: 'https://insight.adsrvr.org/track/pxl',
  recaptchaKey: '6LeRoQ8pAAAAABUHLjEtzM7LG-df0jVZ7rMswd8i',
  recaptchaAction: 'reserve',
};
