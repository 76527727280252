<main class="container py-4 px-3" [appInert]="isAlertPromptVisible$ | async">
  <div class="row mb-5">
    <div class="col-12">
      <div class="d-flex gap-3">
        <div *ngIf="location.logoPath">
          <img aria-hidden="true" class="logo" [src]="location.logoPath" alt="" />
        </div>
        <div>
          <h1 class="m-0 text-primary">{{ location.locationName }}</h1>

          <p class="text-bold m-0 pt-2 pb-2" *ngIf="location.phone && !suppressSearchHeader">
            {{ location.phone }}
          </p>

          <app-location-address
            [address]="location.address"
            [distance]="location.distance"
            [city]="location.city"
            [state]="location.state"
          ></app-location-address>
        </div>
      </div>
    </div>
    <div class="col-12">
      <app-location-contacts
        [location]="location"
        [hideDirections]="true"
        (openExternalUrl)="openExternalUrl($event)"
        (sendAnalyticsEvent)="sendAnalyticsEvent()"
      ></app-location-contacts>
    </div>
  </div>

  <ng-container *ngIf="selectedVaccineName && !suppressSearchHeader">
    <h2 class="text-primary">
      {{ 'APPOINTMENT.LOCATION.SELECTED_VACCINE_HEADER' | translate }}
    </h2>

    <div class="step-info justify-content-between align-items-start mb-5">
      <div class="d-flex align-items-start justify-content-between w-100 gap-2">
        <div class="d-flex gap-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 2147207565">
              <circle id="Ellipse 47" cx="12" cy="12" r="12" fill="#0DA84F" />
              <path
                id="Vector4"
                d="M17.1764 8.27305C17.3756 8.45522 17.4918 8.70645 17.4996 8.97152C17.5073 9.23658 17.406 9.49377 17.2178 9.68655L11.3602 15.6872C11.2652 15.7843 11.1511 15.862 11.0247 15.9157C10.8982 15.9694 10.7618 15.998 10.6237 15.9999C10.4856 16.0018 10.3485 15.9768 10.2205 15.9266C10.0925 15.8763 9.97622 15.8017 9.87853 15.7072L6.77743 12.7069C6.59484 12.5173 6.49544 12.2666 6.50016 12.0075C6.50489 11.7484 6.61337 11.5011 6.80276 11.3179C6.99215 11.1347 7.24766 11.0297 7.51546 11.0251C7.78326 11.0206 8.04244 11.1167 8.23839 11.2934L10.5883 13.5657L15.7155 8.31306C15.9038 8.12036 16.1634 8.0079 16.4374 8.0004C16.7114 7.9929 16.9772 8.09097 17.1764 8.27305Z"
                fill="white"
              />
            </g>
          </svg>

          <span class="text-bold ps-2 text-primary">{{ selectedVaccineName }}</span>
        </div>

        <button
          [attr.aria-label]="'APPOINTMENT.SEARCH.BACK_BUTTON_ARIA_LABEL' | translate"
          class="btn btn-clear-primary btn-sm p-0"
          (click)="backToVaccinePicker()"
          [innerHtml]="'BUTTON.EDIT' | translate"
        ></button>
      </div>
    </div>
  </ng-container>

  <app-date-picker
    *ngIf="location.supportsApiScheduling || hasAppointmentBookingUrl"
    [dates]="dates"
    [appointments]="filteredAppointments"
    [title]="'APPOINTMENT.LOCATION.DAYS' | translate"
    (selectedDate)="setStartDate($event)"
  ></app-date-picker>

  <app-time-picker
    *ngIf="location.supportsApiScheduling || hasAppointmentBookingUrl"
    [appointments]="filteredAppointments"
    [startDate]="startDate"
    [loading]="loading"
    [title]="'APPOINTMENT.LOCATION.TIMES' | translate"
    (selectedAppointmentTime)="selectedAppointmentTime($event)"
  ></app-time-picker>

  <app-loading-time-picker
    *ngIf="loading && (location.supportsApiScheduling || hasAppointmentBookingUrl)"
  ></app-loading-time-picker>

  <!--
    Location doesn't support backbone API scheduling
    Location has appointments but times do not have booking URL
  -->
  <div
    class="mt-4"
    *ngIf="
      !location.supportsApiScheduling &&
      !hasAppointmentBookingUrl &&
      location.bookingUrl &&
      appointments.length > 0
    "
  >
    <p class="text-uppercase text-tertiary text-small mb-0">
      {{
        'APPOINTMENT.LOCATION.AVAILABLE_APPOINTMENTS'
          | translate : { appointmentsLength: appointments.length }
      }}
    </p>

    <ng-container *ngFor="let appointment of appointments.slice(0, 3); let last = last; index as i">
      <span class="text-bold text-heading text-secondary">{{ appointment.prettyTime }}</span>
      <span class="text-bold text-heading text-secondary" *ngIf="!appointment.isToday">
        {{ getAppointmentDayOfWeek(appointment.date) }}
      </span>
      <span class="text-heading text-secondary" *ngIf="i <= 1 && !last">,&nbsp;</span>
    </ng-container>

    <span class="text-heading text-secondary text-bold" *ngIf="appointments.length > 3">
      {{ 'APPOINTMENT.LOCATION.AVAILABLE_APPOINTMENTS_OVERFLOW' | translate }}
    </span>
  </div>

  <!--
        Location doesn't support backbone API scheduling
        Location has appointments times with a bookingUrl or location has a bookingUrl
        Need to display the redirection disclaimer
      -->
  <div
    *ngIf="
      !location.supportsApiScheduling &&
      (hasAppointmentBookingUrl || location.bookingUrl || location.informationalUrl)
    "
    class="mt-3 pt-3 d-flex flex-column align-items-center"
  >
    <p class="text-secondary text-center">
      {{ 'APPOINTMENT.LOCATION.REDIRECTION_DISCLAIMER' | translate }}
    </p>
  </div>

  <app-footer
    class="pt-4 d-block"
    [leftButtonName]="leftButtonName"
    (leftButtonClicked)="leftButtonClick()"
    [rightButtonName]="rightButtonName"
    [rightButtonDisabled]="rightButtonDisabled"
    [rightButtonClass]="rightButtonClass"
    (rightButtonClicked)="rightButtonClick()"
  ></app-footer>
</main>

<app-alert-prompt
  *ngIf="isAlertPromptVisible$ | async"
  [titleTC]="alertPrompt?.titleTC"
  [subtitleTC]="alertPrompt?.subtitleTC"
  [cancelTextTC]="alertPrompt?.cancelTextTC"
  [continueTextTC]="alertPrompt?.continueTextTC"
  (cancelAction)="alertPromptCancel()"
  (continueAction)="alertPromptContinue()"
></app-alert-prompt>
