<div *ngIf="!suppressHero" class="row g-0 app-hero" aria-hidden="true">
  <div id="hero-background" class="position-relative">
    <div id="hero-background-foreground"></div>
    <h1>{{ 'HERO.TITLE' | translate }}</h1>
  </div>

  <div class="hero-footer row m-0">
    <div class="step col-md-4 text-center" [ngClass]="{ active: isActive(1) }">
      <div
        class="step-container justify-content-md-center"
        [ngClass]="!isActive(1) ? ['d-none', 'd-md-flex', 'd-lg-flex', 'd-xl-flex'] : ''"
      >
        <div class="step-number">
          <div class="step-number-background"></div>
          <ng-container *ngIf="isStepComplete(1); else showStepNumber1">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="radio-button">
                <g id="fluent:checkmark-12-filled">
                  <path
                    id="Vector1"
                    d="M22.2747 11.3413C22.5156 11.569 22.6561 11.883 22.6655 12.2143C22.6749 12.5455 22.5523 12.867 22.3247 13.1079L15.2414 20.6079C15.1266 20.7293 14.9887 20.8264 14.8357 20.8935C14.6827 20.9606 14.5179 20.9964 14.3509 20.9987C14.1838 21.0011 14.018 20.9699 13.8633 20.9071C13.7085 20.8442 13.5679 20.751 13.4497 20.6329L9.69974 16.8829C9.47894 16.646 9.35874 16.3326 9.36445 16.0087C9.37017 15.6849 9.50135 15.3759 9.73037 15.1469C9.9594 14.9179 10.2684 14.7867 10.5922 14.781C10.916 14.7753 11.2295 14.8955 11.4664 15.1163L14.3081 17.9563L20.5081 11.3913C20.7358 11.1504 21.0498 11.0099 21.3811 11.0005C21.7123 10.9911 22.0338 11.1137 22.2747 11.3413Z"
                    fill="var(--vax-primary)"
                  />
                </g>
              </g>
            </svg>
          </ng-container>
          <ng-template #showStepNumber1>
            <div class="step-number-value text-heading">1</div>
          </ng-template>
        </div>
        <h3 class="m-0 text-heading text-primary">{{ 'HERO.STEP_1' | translate }}</h3>
      </div>
    </div>

    <div class="step col-md-4 text-center" [ngClass]="{ active: isActive(2) }">
      <div
        class="step-container justify-content-md-center"
        [ngClass]="!isActive(2) ? ['d-none', 'd-md-flex', 'd-lg-flex', 'd-xl-flex'] : ''"
      >
        <div class="step-number">
          <div class="step-number-background"></div>
          <ng-container *ngIf="isStepComplete(2); else showStepNumber2">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="radio-button">
                <g id="fluent:checkmark-12-filled">
                  <path
                    id="Vector2"
                    d="M22.2747 11.3413C22.5156 11.569 22.6561 11.883 22.6655 12.2143C22.6749 12.5455 22.5523 12.867 22.3247 13.1079L15.2414 20.6079C15.1266 20.7293 14.9887 20.8264 14.8357 20.8935C14.6827 20.9606 14.5179 20.9964 14.3509 20.9987C14.1838 21.0011 14.018 20.9699 13.8633 20.9071C13.7085 20.8442 13.5679 20.751 13.4497 20.6329L9.69974 16.8829C9.47894 16.646 9.35874 16.3326 9.36445 16.0087C9.37017 15.6849 9.50135 15.3759 9.73037 15.1469C9.9594 14.9179 10.2684 14.7867 10.5922 14.781C10.916 14.7753 11.2295 14.8955 11.4664 15.1163L14.3081 17.9563L20.5081 11.3913C20.7358 11.1504 21.0498 11.0099 21.3811 11.0005C21.7123 10.9911 22.0338 11.1137 22.2747 11.3413Z"
                    fill="var(--vax-primary)"
                  />
                </g>
              </g>
            </svg>
          </ng-container>
          <ng-template #showStepNumber2>
            <div class="step-number-value text-heading">2</div>
          </ng-template>
        </div>
        <h3 class="m-0 text-heading text-primary">{{ 'HERO.STEP_2' | translate }}</h3>
      </div>
    </div>

    <div class="step col-md-4 text-center" [ngClass]="{ active: isActive(3) }">
      <div
        class="step-container justify-content-md-center"
        [ngClass]="!isActive(3) ? ['d-none', 'd-md-flex', 'd-lg-flex', 'd-xl-flex'] : ''"
      >
        <div class="step-number">
          <div class="step-number-background"></div>
          <ng-container *ngIf="isStepComplete(3); else showStepNumber3">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="radio-button">
                <g id="fluent:checkmark-12-filled">
                  <path
                    id="Vector3"
                    d="M22.2747 11.3413C22.5156 11.569 22.6561 11.883 22.6655 12.2143C22.6749 12.5455 22.5523 12.867 22.3247 13.1079L15.2414 20.6079C15.1266 20.7293 14.9887 20.8264 14.8357 20.8935C14.6827 20.9606 14.5179 20.9964 14.3509 20.9987C14.1838 21.0011 14.018 20.9699 13.8633 20.9071C13.7085 20.8442 13.5679 20.751 13.4497 20.6329L9.69974 16.8829C9.47894 16.646 9.35874 16.3326 9.36445 16.0087C9.37017 15.6849 9.50135 15.3759 9.73037 15.1469C9.9594 14.9179 10.2684 14.7867 10.5922 14.781C10.916 14.7753 11.2295 14.8955 11.4664 15.1163L14.3081 17.9563L20.5081 11.3913C20.7358 11.1504 21.0498 11.0099 21.3811 11.0005C21.7123 10.9911 22.0338 11.1137 22.2747 11.3413Z"
                    fill="var(--vax-primary)"
                  />
                </g>
              </g>
            </svg>
          </ng-container>
          <ng-template #showStepNumber3>
            <div class="step-number-value text-heading">3</div>
          </ng-template>
        </div>
        <h3 class="m-0 text-heading text-primary">{{ 'HERO.STEP_3' | translate }}</h3>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!suppressHero" class="row g-0 pt-4">
  <div class="row m-0 step-breadcrumb">
    <div class="col-md-4">
      <div
        class="step-info justify-content-between align-items-start"
        *ngIf="isStepComplete(1) && selectedVaccineName"
      >
        <div class="d-flex align-items-start justify-content-between w-100 gap-2">
          <div class="d-flex gap-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Group 2147207565">
                <circle id="Ellipse 47" cx="12" cy="12" r="12" fill="#0DA84F" />
                <path
                  id="Vector4"
                  d="M17.1764 8.27305C17.3756 8.45522 17.4918 8.70645 17.4996 8.97152C17.5073 9.23658 17.406 9.49377 17.2178 9.68655L11.3602 15.6872C11.2652 15.7843 11.1511 15.862 11.0247 15.9157C10.8982 15.9694 10.7618 15.998 10.6237 15.9999C10.4856 16.0018 10.3485 15.9768 10.2205 15.9266C10.0925 15.8763 9.97622 15.8017 9.87853 15.7072L6.77743 12.7069C6.59484 12.5173 6.49544 12.2666 6.50016 12.0075C6.50489 11.7484 6.61337 11.5011 6.80276 11.3179C6.99215 11.1347 7.24766 11.0297 7.51546 11.0251C7.78326 11.0206 8.04244 11.1167 8.23839 11.2934L10.5883 13.5657L15.7155 8.31306C15.9038 8.12036 16.1634 8.0079 16.4374 8.0004C16.7114 7.9929 16.9772 8.09097 17.1764 8.27305Z"
                  fill="white"
                />
              </g>
            </svg>

            <strong class="ps-2 text-primary">{{ selectedVaccineName }}</strong>
          </div>

          <button
            [attr.aria-label]="'APPOINTMENT.SEARCH.BACK_BUTTON_ARIA_LABEL' | translate"
            class="btn btn-clear-primary btn-sm p-0"
            (click)="backToVaccinePicker()"
            [innerHtml]="'BUTTON.EDIT' | translate"
          ></button>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div
        class="step-info justify-content-between"
        *ngIf="isStepComplete(2) && selectedAppointment"
      >
        <div class="d-flex align-items-start justify-content-between w-100 gap-2">
          <div class="d-flex gap-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Group 2147207565">
                <circle id="Ellipse 47" cx="12" cy="12" r="12" fill="#0DA84F" />
                <path
                  id="Vector5"
                  d="M17.1764 8.27305C17.3756 8.45522 17.4918 8.70645 17.4996 8.97152C17.5073 9.23658 17.406 9.49377 17.2178 9.68655L11.3602 15.6872C11.2652 15.7843 11.1511 15.862 11.0247 15.9157C10.8982 15.9694 10.7618 15.998 10.6237 15.9999C10.4856 16.0018 10.3485 15.9768 10.2205 15.9266C10.0925 15.8763 9.97622 15.8017 9.87853 15.7072L6.77743 12.7069C6.59484 12.5173 6.49544 12.2666 6.50016 12.0075C6.50489 11.7484 6.61337 11.5011 6.80276 11.3179C6.99215 11.1347 7.24766 11.0297 7.51546 11.0251C7.78326 11.0206 8.04244 11.1167 8.23839 11.2934L10.5883 13.5657L15.7155 8.31306C15.9038 8.12036 16.1634 8.0079 16.4374 8.0004C16.7114 7.9929 16.9772 8.09097 17.1764 8.27305Z"
                  fill="white"
                />
              </g>
            </svg>

            <div class="d-flex flex-column">
              <strong class="text-primary">{{ selectedAppointment.location.locationName }}</strong>
              <app-location-address
                [address]="selectedAppointment.location.address"
                [distance]="selectedAppointment.location.distance"
                [city]="selectedAppointment.location.city"
                [state]="selectedAppointment.location.state"
              ></app-location-address>

              <strong class="pt-2 text-primary">
                {{ prettyDate | translate }}, {{ prettyTime }}
              </strong>
            </div>
          </div>

          <button
            [attr.aria-label]="'APPOINTMENT.SEARCH.BACK_BUTTON_ARIA_LABEL' | translate"
            class="btn btn-clear-primary btn-sm p-0"
            (click)="backToAppointmentSearch()"
            [innerHtml]="'BUTTON.EDIT' | translate"
          ></button>
        </div>
      </div>
    </div>

    <div class="col-md-4" *ngIf="isStepComplete(3)"></div>
  </div>
</div>
