<main class="container py-4 px-3">
  <header class="mb-3">
    <app-hero
      [currentStep]="3"
      [selectedVaccineName]="selectedVaccineName"
      [selectedAppointment]="selectedAppointment"
      [suppressHero]="suppressSearchHeader"
    ></app-hero>
  </header>

  <div
    id="review-container"
    [class.border-none]="suppressSearchHeader"
    [class.mt-4]="!suppressSearchHeader"
  >
    <h1 class="text-primary" [class.pt-5]="!suppressSearchHeader">
      {{ 'APPOINTMENT.REVIEW.TITLE' | translate }}
    </h1>

    <app-appointment-detail-card></app-appointment-detail-card>

    <p class="text-danger" *ngIf="recaptchaError">{{ recaptchaError }}</p>
    <p class="text-danger" *ngIf="error">{{ error }}</p>

    <app-footer
      [leftButtonName]="'BUTTON.BACK' | translate"
      [leftButtonDisabled]="(loading$ | async) === true"
      (leftButtonClicked)="leftButtonClick()"
      [rightButtonLoading]="(loading$ | async) === true"
      [rightButtonDisabled]="
        (loading$ | async) === true || recaptchaRetryCount >= recaptchaRetryLimit
      "
      [rightButtonName]="'APPOINTMENT.REVIEW.CONFIRM_APPOINTMENT_BUTTON' | translate"
      [rightButtonIncludeRecaptcha]="true"
      (rightButtonClicked)="rightButtonClick($event)"
    ></app-footer>

    <div
      id="recaptcha-privacy"
      class="text-tertiary mb-4 mt-4"
      [innerHTML]="safeRecaptchaHtml"
    ></div>
  </div>
</main>
