<main class="container py-4 px-3">
  <header class="mb-3">
    <app-hero
      [currentStep]="3"
      [selectedVaccineName]="selectedVaccineName"
      [selectedAppointment]="selectedAppointment"
      [suppressHero]="suppressSearchHeader"
    ></app-hero>
  </header>

  <div
    id="confirmed-container"
    [class.border-none]="suppressSearchHeader"
    [class.mt-4]="!suppressSearchHeader"
  >
    <h1 class="text-primary" [class.pt-5]="!suppressSearchHeader">
      {{ 'APPOINTMENT.CONFIRMATION.TITLE' | translate }}
    </h1>
    <p class="text-tertiary">{{ 'APPOINTMENT.CONFIRMATION.SUBTITLE' | translate }}</p>

    <h5 id="disclaimer" class="mb-4 d-inline-block">
      {{
        'APPOINTMENT.CONFIRMATION.CANCEL_DISCLAIMER' | translate : { locationName: locationName }
      }}
    </h5>

    <app-appointment-detail-card></app-appointment-detail-card>

    <app-footer
      [rightButtonName]="'APPOINTMENT.CONFIRMATION.DONE_BUTTON' | translate"
      [rightButtonLoading]="(loading$ | async) === true"
      [rightButtonDisabled]="(loading$ | async) === true"
      (rightButtonClicked)="done()"
    ></app-footer>
  </div>
</main>
